import './styles.scss';

import React, { useRef, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated, interpolate } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { PageContext } from '@assets/scripts/context/page-context';
import useSharedSetter from '@assets/scripts/hooks/useSharedSetter';
import useResizeObserver from '@assets/scripts/hooks/useResizeObserver';

import iconEye from '@assets/icons/eye.svg';

const BREAKPOINTS = [
	// Warning: Order is important (biggest to smallest)
	// since we will want to match the first item that is smaller than the viewport width
	// desktop
	{
		size: 1200,
		viewBox: [1432, 8709],
		distLogoFromTop: 362,
		bounds: { top: 115 },
	},
	// laptop
	{
		size: 900,
		viewBox: [1000, 6236],
		distLogoFromTop: 349,
		bounds: { top: 115 },
	},
	// tablet
	{
		size: 768,
		viewBox: [750, 4677],
		distLogoFromTop: 262,
		bounds: { top: 100 },
	},
	// mobile
	{
		size: 0,
		viewBox: [400, 4421],
		distLogoFromTop: 328,
		bounds: { top: 100 },
	},
];

const CultureFrame = ({ top, bottom, mouse, hint /*, ...otherProps*/ }) => {
	// refs
	const refPageWrapper = useRef();
	const refSvg = useRef();
	const refBgMask = useRef();
	const refSvgMouse = useRef();
	// vars
	const releaseMaskScaleMouse = useRef(() => {});
	const releaseMaskScaleTouch = useRef(() => {});
	const isInitialPositionSet = useRef(false);
	const svgRatio = useRef();
	const svgScale = useRef(1);
	const svgCurrentBreakpoint = useRef({
		size: -1,
		viewBox: [0, 0],
		distLogoFromTop: 328,
	});
	const svgCurrentRect = useRef({
		x: 0,
		width: 0,
	});
	const isDraggingCircle = useRef(false);
	// const oldTouchedElement = useRef();
	// const touchedElement = useRef();
	// const resetPosition = useRef();
	const isMaskReady = useRef(false);
	const isMaskActive = useRef(true);
	const { isMenuOpen, isUsingTouch } = useContext(PageContext);
	const hasDraggedCircle = useRef(false);

	// --------------------------------
	// #region Springs
	// --------------------------------

	// define springs
	const [maskPosition, setPosition] = useSpring(() => ({
		xy: [0, 0],
		immediate: () => !isInitialPositionSet.current,
		config: { mass: 10, tension: 800, friction: 140 },
	}));

	const [maskPosition2, setPosition2] = useSpring(() => ({
		xy: [0, 0],
		config: { mass: 10, tension: 800, friction: 140 },
	}));

	const [maskScale, setScale] = useSpring(() => ({
		scale: 0,
		config: { mass: 3, tension: 300, friction: 50 },
	}));

	const [hintScale, setHintScale] = useSpring(() => ({
		scale: 0,
	}));

	const captureMaskPosition = useSharedSetter(
		// unique name
		'maskPosition',
		// handler - how to run the animation
		(xy) => {
			setPosition({ xy: xy });
			if (!isInitialPositionSet.current) {
				isInitialPositionSet.current = true;
			}
		},
		// initial value
		[0, 0]
	);

	const captureMaskScale = useSharedSetter(
		'maskScale',
		(s) => setScale({ scale: s }),
		0
	);

	const captureHintScale = useSharedSetter(
		'hintScale',
		([scale, config = {}]) => setHintScale({ scale, config }),
		0
	);

	const bindDrag = useDrag(({ down, movement: [mx, my] }) => {
		hasDraggedCircle.current = true;
		setPosition2({
			xy: down && isUsingTouch ? [mx, my] : [0, 0],
		});
		isDraggingCircle.current = down;
	});

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Functions
	// --------------------------------

	// touch: position the circle on the (8) logo initially
	const initialCircleDistanceTop = () =>
		svgCurrentBreakpoint.current.distLogoFromTop / svgScale.current;

	/**
	 * Check if the circle is over the header
	 * @param {Number} top [px] distance from the top of the document to the position of the circle
	 */
	const isCircleOutOfBoundsTop = useCallback((top) => {
		return (
			typeof svgCurrentBreakpoint.current?.bounds?.top !== 'undefined' &&
			svgCurrentRect.current?.height &&
			top < svgCurrentBreakpoint.current.bounds.top
		);
	}, []);

	/**
	 * Check if the circle is over the footer
	 * @param {Number} top [px] distance from the top of the document to the position of the circle
	 */
	const isCircleOutOfBoundsBottom = useCallback((top) => {
		return (
			typeof svgCurrentBreakpoint.current?.bounds?.top !== 'undefined' &&
			svgCurrentRect.current?.height &&
			top > svgCurrentRect.current.height
		);
	}, []);

	/**
	 * Check if the circle is over the header/footer
	 * @param {Number} top [px] distance from the top of the document to the position of the circle
	 */
	const isCircleOutOfBounds = useCallback(
		(top) => {
			return (
				isCircleOutOfBoundsTop(top) || isCircleOutOfBoundsBottom(top)
			);
		},
		[isCircleOutOfBoundsTop, isCircleOutOfBoundsBottom]
	);

	const revealMask = useCallback(() => {
		if (isMaskReady.current) return;
		isMaskReady.current = true;
		captureMaskPosition(({ pointerXY, scrollXYClampedTop, viewportWH }) => {
			if (isUsingTouch) {
				return [
					viewportWH[0] / 2,
					initialCircleDistanceTop() + scrollXYClampedTop[1],
				];
			} else {
				return [pointerXY[0], pointerXY[1] + scrollXYClampedTop[1]];
			}
		});
		captureMaskScale(({ pointerXY, scrollXYClampedTop }) => {
			// are we out of bounds?
			const y = isUsingTouch ? initialCircleDistanceTop() : pointerXY[1];
			const top = y + scrollXYClampedTop[1];
			if (isCircleOutOfBounds(top)) return 0;

			// defaults
			if (isUsingTouch) return 0.6; // using touch
			return 0.2; // using mouse
		});
		setTimeout(() => {
			captureHintScale(() => {
				const isVisible = isUsingTouch && !hasDraggedCircle.current;
				return [
					isVisible ? 0.8 : 0, // scale
					isVisible
						? { mass: 1, tension: 400, friction: 50, clamp: true }
						: { mass: 1, tension: 900, friction: 50, clamp: true }, // config
				];
			});
		}, 500);
	}, [
		captureMaskPosition,
		captureMaskScale,
		captureHintScale,
		isUsingTouch,
		isCircleOutOfBounds,
	]);

	const updateSvgPos = useCallback(() => {
		// const vw = document.documentElement.clientWidth;
		const vw = window.innerWidth;

		// update current breakpoint
		const lastSize = svgCurrentBreakpoint.current.size;
		svgCurrentBreakpoint.current = BREAKPOINTS.find((bp) => vw >= bp.size);

		// if viewBox changed
		if (svgCurrentBreakpoint.current.size !== lastSize) {
			// update viewBox
			refSvg.current.setAttribute(
				'viewBox',
				`0 0 ${svgCurrentBreakpoint.current.viewBox[0]} ${svgCurrentBreakpoint.current.viewBox[1]}`
			);

			// update svg ratio
			svgRatio.current =
				svgCurrentBreakpoint.current.viewBox[1] /
				svgCurrentBreakpoint.current.viewBox[0];
			// if not on smallest breakpoint add max width/height
			refSvg.current.style.maxWidth =
				svgCurrentBreakpoint.current.size > 0
					? svgCurrentBreakpoint.current.viewBox[0] + 'px'
					: '';
			refSvg.current.style.maxHeight =
				svgCurrentBreakpoint.current.size > 0
					? svgCurrentBreakpoint.current.viewBox[1] + 'px'
					: '';
		}

		// save current svg size, position
		svgCurrentRect.current = {
			x: refSvg.current.getBoundingClientRect().x,
			width: refSvg.current.getBoundingClientRect().width,
			height:
				refSvg.current.getBoundingClientRect().width * svgRatio.current,
		};

		// scale of svg content
		svgScale.current =
			svgCurrentBreakpoint.current.viewBox[0] /
			svgCurrentRect.current.width;

		// Fix for page transition:
		// wrapper html element has to have the current svg's height
		// so on page transition it doesn't jump to the top of the page
		refPageWrapper.current.style.height =
			svgCurrentRect.current.height + 'px';
	}, []);

	// ⚡️ touch start
	const onTouchStart = () => {
		if (!isMaskActive.current) return;
		releaseMaskScaleTouch.current = captureMaskScale(
			({ touchXY, scrollXYClampedTop }) => {
				// are we out of bounds?
				const y = isDraggingCircle.current
					? touchXY[1]
					: initialCircleDistanceTop();
				const top = y + scrollXYClampedTop[1];
				if (isCircleOutOfBoundsBottom(top)) return 0; // NOTE: we check only for bottom, since on touch having the circle disappear in the header is weird and not really necessary

				if (isDraggingCircle.current) return 1; // dragging
				return 0.7; // touching the screen outside of the circle
			}
		);
	};

	// ⚡️ touch end
	const onTouchEnd = () => {
		if (!isMaskActive.current) return;
		releaseMaskScaleTouch.current();
	};

	// ⚡️ mouse moves
	const onMouseMove = (ev) => {
		if (!isMaskActive.current) return;
		revealMask();
		const pointerXY = [ev.clientX, ev.clientY];
		document.removeEventListener('mousemove', onMouseMove);

		// if mouse was already hovering an illustration on load, mouseenter won't be triggered so we force it here
		const hoveredChild = document.elementFromPoint(
			pointerXY[0],
			pointerXY[1]
		);
		const hoveredElement = hoveredChild ? hoveredChild.parentElement : null;
		if (hoveredElement === refSvgMouse.current) {
			mouseEnter();
		}
	};

	// ⚡️ mouse enters
	const mouseEnter = () => {
		if (
			!isMaskReady.current ||
			!isMaskActive.current ||
			isUsingTouch // touch events also trigger mouseenter on some browsers
		)
			return;
		releaseMaskScaleMouse.current = captureMaskScale(() => 1);
	};

	// ⚡️ mouse leave
	const mouseLeave = () => {
		if (!isMaskReady.current || !isMaskActive.current) return;
		releaseMaskScaleMouse.current();
	};

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Hooks
	// --------------------------------

	// when svg size changes
	useResizeObserver(refSvg, updateSvgPos);

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		// document.body.style.position = 'fixed';

		// bind events
		document.addEventListener('touchstart', onTouchStart);
		document.addEventListener('touchend', onTouchEnd);
		document.addEventListener('mousemove', onMouseMove);

		// cleanup
		return () => {
			// document.body.style.position = '';
			document.removeEventListener('touchstart', onTouchStart);
			document.removeEventListener('touchend', onTouchEnd);
			// window.removeEventListener('wheel', enableScroll);
			// document.removeEventListener('keydown', enableScroll);
		};
	}, []);
	/* eslint-enable react-hooks/exhaustive-deps */

	// enable/disable mask
	useEffect(() => {
		isMaskActive.current = !isMenuOpen;
	}, [isMenuOpen]);

	// initialise svg positioning on mount
	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		updateSvgPos();
	}, []);
	/* eslint-enable react-hooks/exhaustive-deps */

	// reveal mask when using touch
	useEffect(() => {
		if (isUsingTouch) revealMask();
	}, [isUsingTouch, revealMask]);

	// --------------------------------
	// #endregion
	// --------------------------------

	return (
		<div className="page-culture__wrapper" ref={refPageWrapper} aria-hidden="true">
			<div className="background-page" data-animation-page></div>

			{/* Invisible handle for touch users to be able to drag the circle */}
			<animated.div
				{...bindDrag()}
				className="background-mask-handle"
				style={{
					transform: interpolate(
						[maskPosition.xy, maskScale.scale],
						([x, y], s) =>
							`translate3d(${x}px,${y}px,0) scale(${s})`
					),
					opacity: 0,
					touchAction: 'none',
					zIndex: 99999,
				}}
			/>

			<animated.div
				className="hint"
				style={{
					transform: interpolate(
						[hintScale.scale],
						(s) => `scale3d(${s}, ${s}, 1)`
					),
					touchAction: 'none',
				}}
			>
				<svg>
					<use xlinkHref={`#${iconEye.id}`} />
				</svg>
				<span>{hint}</span>
			</animated.div>

			{/* Mask Background */}
			<div data-animation-page>
				<animated.div
					ref={refBgMask}
					className="background-mask"
					style={{
						transform: interpolate(
							[
								maskPosition.xy,
								maskPosition2.xy,
								maskScale.scale,
							],
							([x, y], [x2, y2], s) =>
								`translate3d(${x + x2}px,${
									y + y2
								}px,0) scale(${s})`
						),
					}}
				/>
			</div>

			<svg
				preserveAspectRatio="xMidYMin meet"
				className="svg-content"
				ref={refSvg}
				data-animation-page
			>
				{/* Mask Circle */}
				<clipPath className="svg-mask" id="mask-circle">
					<animated.circle
						cx="0"
						cy="0"
						r="160"
						fill="white"
						style={{
							transform: interpolate(
								[
									maskPosition.xy,
									maskPosition2.xy,
									maskScale.scale,
								],
								([x, y], [x2, y2], s) =>
									`translate3d(${
										(x - svgCurrentRect.current.x + x2) *
										svgScale.current
									}px,${
										(y + y2) * svgScale.current
									}px,0) scale(${s * svgScale.current})`
							),
						}}
					/>
				</clipPath>

				{/* Layer Bottom */}
				<svg className="svg-layer svg-bottom">{bottom}</svg>
				{/* Pink Background */}
				<rect
					fill="#FFBCBC"
					x="0"
					y="0"
					width="100%"
					height="100%"
					clipPath="url(#mask-circle)"
					className="svg-background"
				></rect>
				{/* Layer Top */}
				<svg className="svg-layer svg-top" clipPath="url(#mask-circle)">
					{top}
				</svg>
				{/* Layer Mouse */}
				<svg
					ref={refSvgMouse}
					className="svg-layer svg-mouse"
					onMouseEnter={() => mouseEnter()}
					onMouseLeave={() => mouseLeave()}
				>
					{mouse}
				</svg>
			</svg>
		</div>
	);
};

CultureFrame.propTypes = {
	top: PropTypes.node,
	bottom: PropTypes.node,
	mouse: PropTypes.node,
};

export default CultureFrame;
